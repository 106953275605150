// only used on list and detail pages for dialogs //Carolyn's version
var VideoDialog4i = function () {
	const techVideoTitle = "tech trend";
	const mugVideoTitle = "mug imprints";
	let videoPlayer = "";
	let theVideoDialog, productName, productID, productAlias, videoID;
	
	const init = () => {
		const videoBtns = document.getElementsByClassName("videoLink");
		if (videoBtns.length > 0){
			theVideoDialog = new Dialog4i();

			const vidHTML =
					'<a href="#" class="transcriptLink inlineMiddle marginBtm10 text16">View Transcript</a>' +
				'<div id="videoContainer" class="dialogContent videoContent marginBtm15 player-wrapper">' +
						'<div class="spinner" role="alert" aria-busy="true" aria-atomic="true" aria-label="Loading video, please wait">' +
							'<div class="bounce1"></div><div class="bounce2"></div><div class="bounce3"></div>' +
						'</div>' +
					'</div>';
					
			theVideoDialog.Init({
				triggerElements: videoBtns,
				dialogClass: "videoDialog xLDialog",
				title: "Video Highlights",
				content: vidHTML,
				onOpen: getVideo,
				update: updateContent
			});
		}
	};
	
	const setVariables = () => {
		element = document.querySelector(".dialogTrigger");
		videoID = element.getAttribute("data-videoid");
		productID = element.getAttribute("data-productid");
		productAlias = element.hasAttribute("data-productalias") ? element.getAttribute("data-productalias") : "";
		productName = element.hasAttribute("aria-label") ? el.getAttribute("aria-label").replace("Video for ", "") : "";
		if (productName == "" && element.querySelector("svg")) productName = element.querySelector("svg").getAttribute("aria-label").replace("Video for ", "");
	};
	
	const getURL = () => {
		let controllerUrl = "/video/";
		if (productID == null && videoID != null)
				controllerUrl += "VideoDetailsByID/" + videoID;
		else if (videoID == null && productID != null)
				controllerUrl += "VideoDetails/" + productID;
		else controllerUrl += "VideoDetailsByVideoAndProductId/" + videoID + "/" + productID;
		return controllerUrl;
	};
	
	const setUpVideo = async () => {
		setVariables();
		const url = getURL();
		let responseJSON;
		try{
			const response = await fetch(url, {
				method: 'GET', 
				headers: { 'Content-Type': 'application/json', 'X-Requested-With': 'fetch' }
			});
			if(!response.ok) throw new Error(`Error processing video request: ${response.statusText}`);
			responseJSON = await response.json();
		}catch(error){
			console.error(error);
		}

		if(responseJSON){
			updateContent(responseJSON);
			if (typeof bitmovin == 'function') initPlayer(responseJSON);
			else loadScript(initPlayer, responseJSON);
		}
	};
	
	const getVideo = () => {
		setUpVideo();
	};
    	
   	const initPlayer = (data) => {
   		const videoHTML = '<div id="player"></div>';
   		const id = data.wv.VideoID;
   		const url = data.wv.AssetUrl;
   		const captions = data.wv.EnglishSubtitleUrl;
   		const title = data.wv.VideoTitle;
   	 	const vidContainer = document.getElementById("videoContainer");
   		if (vidContainer) vidContainer.innerHTML = videoHTML;
   		
   		let analyticsTitle = productAlias;
   		let videoTitle = productName;
   		if (title != null && title.length > 0) {
   			analyticsTitle = title; // for non-product (or more general) vids
   			videoTitle = title;
   			if(title.toLowerCase() == "mug imprints") videoTitle = "Video for Mug Imprint Options"; 
   			if(title.toLowerCase() == techVideoTitle) videoTitle = "Video for Technology Trends";
   		}
   		
   		var conf = {
		        key: 'a8072f85-5072-454b-b8f2-09087b192aa7',
		        analytics: {
		            key: '26a9da30-39dd-4906-bf18-2b222b809d92',
		            title: analyticsTitle, // controls the video name displayed in Analytics
		            videoId: id, // the actual id needed for tracking in Analytics
		        },
		        playback: {
			    autoplay: true,
			    muted: false
			  }
		    };
		
		    var source = {
		        title: videoTitle,
		        dash: url + id + '/stream.mpd',
		        hls: url + id + '/index.m3u8',
		        subtitleTracks: [{
		            //url: captions + id + '.vtt',
		            url: captions,
		            id: 'track-0',
		            label: 'English',
            			lang: 'en'
		        }],
		        thumbnailTrack: {
		            url: url + id + '/sprites/low/sprite.vtt',
		        }
		    };

    		videoPlayer = new bitmovin.player.Player(document.getElementById('player'), conf);
    		videoPlayer.load(source);
    	};
    	const loadScript = (callback, data) => {
    		const head = document.head;
    		const script = document.createElement('script');
    		script.type = 'text/javascript';
    		script.src = '//cdn.jsdelivr.net/npm/bitmovin-player@8/bitmovinplayer.js';
    		script.onload = (() => { callback(data); });
    		head.appendChild(script);
    	};
    	
    	const bindNewLinks = () => {
    		const vidLinks = document.getElementsByClassName("videoLink");
    		if (vidLinks.length > 0) theVideoDialog.BindTriggerElements(vidLinks);
    	};
  
    	const updateContent = (data) =>{
    		const dialogHeading = document.querySelector(".videoDialog").querySelector("h2");
    		if(document.querySelector(".videoDialog .vidProdInfo")) document.querySelector(".videoDialog .vidProdInfo").remove();
    		
    		if (data.wv.VideoTitle != null && data.wv.VideoTitle.toLowerCase() == mugVideoTitle){ 
    			dialogHeading.textContent = "Mug Imprint Options";
		}else if(data.wv.VideoTitle != null && data.wv.VideoTitle.toLowerCase() == techVideoTitle){
			dialogHeading.textContent = "Trending Technology Items";
		}else{
			//todo get product name form a data-productname attribute instead
			const prodHTML = `<div class="vidProdInfo"><h3 class="textSemiBold text18 marginBtm5">${productName}</h3><p class="text16">Item # ${productAlias}</p></div>`;
		    	document.querySelector(".videoDialog .transcriptLink").insertAdjacentHTML("beforebegin", prodHTML);
		}
		showTranscriptLink(data);
		if (data.videos != null && data.videos.length > 1) addMoreVideoLinks(data);
	};
	const addMoreVideoLinks = (data) => {
		const otherVideos = document.getElementsByClassName("otherVidContainer");
    		if (otherVideos.length > 0) otherVideos[0].remove();

    		let otherVideoLinks = '<div class="otherVidContainer displayFlex flexSpaceBetween flexAlignItemsCenter">';
    		for (let i = 0; i < data.videos.length; i++) {
    			let moreVid = data.videos[i];
    			if (moreVid.VideoID != data.wv.VideoID) {
            			otherVideoLinks += '<button play-video class="videoLink btnToHyp pointer text16 underline padding10"' +
            				'" data-productid="' + productID + '" data-videoid="' + moreVid.VideoID +
            				'" data-productalias="' + productAlias + '" aria-label="' + productName + '">';
            			if (moreVid.VideoTitle != null && moreVid.VideoTitle.toLowerCase() == mugVideoTitle)
            				otherVideoLinks += 'Watch video about imprint options on mugs.</button>';
            			else otherVideoLinks += 'Watch ' + productName + '.</button>';
    			}
    		}
    		otherVideoLinks += '</div>';
    		const videoContainer = document.getElementById("videoContainer");
    		videoContainer.insertAdjacentHTML('afterend', otherVideoLinks);
    		
    		const otherVideosWrapper = document.querySelector(".otherVidContainer");
    		const otherVidLinks = otherVideosWrapper.querySelectorAll(".videoLink");
    		for (let x = 0; x < otherVidLinks.length; x++) {
        		otherVidLinks[x].removeEventListener("click", openNewVideo);
        		otherVidLinks[x].addEventListener("click", openNewVideo);
    		}
	};
   	const showTranscriptLink = (data) =>{
   		const transcriptLink = document.querySelector(".transcriptLink");
   		//todo for tech videos?
    		if (data.wv.VideoTitle != null && data.wv.VideoTitle.toLowerCase() == mugVideoTitle)
            		transcriptLink.setAttribute("href", "/video#mugimprints"); // go to video product page
    		else if (data.wv.VideoTitle != null && data.wv.VideoTitle.length > 0)
    			transcriptLink.setAttribute("href", "/video/id/" + data.wv.VideoID + "#transcript"); // go to video page
    		else if (productAlias !== "")
            		transcriptLink.setAttribute("href", "/video/product/" + productAlias + "#transcript"); // go to video product page
    		else transcriptLink.classList.add("hide");
    	};
    	
    	const openNewVideo = (e) => {
    		e.preventDefault();
    		const element = e.currentTarget;
    		setUpVideo();
    	};

	return {
    		Init: init,
    		BindNewLinks: bindNewLinks
	};
};

let videoJS4i; //global for use in list page javascript when paging link is clicked
(async () =>{
	videoJS4i = new VideoDialog4i();
	videoJS4i.Init();
})();